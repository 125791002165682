import React from 'react'
import {Route, Routes} from "react-router-dom"
import Home from './views/Home'
import {Provider} from "react-redux"
import {history, persistor, store} from "./redux/store"
import {PersistGate} from 'reduxjs-toolkit-persist/integration/react'
import {HistoryRouter as Router} from "redux-first-history/rr6"

function AppRouter() {
    return (
        <Routes>
            <Route exact path="/" element={<Home/>}/>
        </Routes>
    )
}

export default function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router history={history}>
                    <AppRouter/>
                </Router>
            </PersistGate>
        </Provider>
    )
}
