import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    loginInfo: {},
    issuerInfo: {},
}
export const issuerReducer = createSlice({
    name: 'issuer',
    initialState,
    reducers: {
        updateLoginInfo: (state, action) => {
            state.loginInfo = action.payload
        },
        updateIssuerInfo: (state, action) => {
            state.issuerInfo = action.payload
        }
    }
})

export const {updateLoginInfo, updateIssuerInfo} = issuerReducer.actions

export default issuerReducer.reducer
