import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    error: '',
    feedbackWindow: false
}

export const userReducer = createSlice({
    name: 'fetch',
    initialState,
    reducers: {
        apiCallRequest: (state, action) => {
            state.loading = true
            state.error = ''
            state.feedbackWindow = false
        },
        apiCallSuccess: (state, action) => {
            state.loading = false
            state.error = ''
            state.feedbackWindow = false
        },
        apiCallFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.feedbackWindow = false
        }
    }
})

export const {apiCallRequest, apiCallSuccess, apiCallFailure} = userReducer.actions

export default userReducer.reducer
