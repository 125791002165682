import React from 'react'
import styles from '../assets/styles/home.module.scss'
import 'antd/dist/antd.css'
import {Divider} from 'antd'
import {HiOutlineMail} from "react-icons/hi"

export default function Home() {

    return (
        <div id="container" className={styles.container}>
            <div className={styles.logo_box}>
                <button className={styles.apply_link}
                        onClick={() => {
                            window.location.href = 'https://events.huilangnft.com'
                        }}>
                    数字藏品发行申请
                </button>
                <a href="/">
                    <img className={styles.img_box} src={require('../assets/images/logo-black.png')} alt="LOGO"/>
                </a>
            </div>
            <div className={styles.content_box}>
                <div className={styles.slogan_box}>
                    <div className={styles.slogan}>数字藏品<br/>一站式发行平台</div>
                </div>
            </div>
            <div className={styles.bottom_navigation}>
                联系我们：<HiOutlineMail/>&nbsp;
                <a href="mailto:business@huilangnft.com" target="_blank" rel="noopener noreferrer">business@huilangnft.com</a>
                <Divider className={styles.divider_box} type="vertical"/>
                <span>微信公众号：回廊数藏</span> <Divider className={styles.divider_box} type="vertical"/>
                <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">沪ICP备2022008817号-1</a>
                <Divider className={styles.divider_box} type="vertical"/>
                <span>ICP许可证：沪B2-20221121</span>
            </div>
        </div>
    );
}

