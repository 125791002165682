import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {createReduxHistoryContext} from "redux-first-history"
import {createBrowserHistory} from 'history'
import rootReducer from './reducers/root-reducer'
import storage from 'reduxjs-toolkit-persist/lib/storage'
import {persistReducer, persistStore, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from 'reduxjs-toolkit-persist'

// use router in redux
const {createReduxHistory, routerMiddleware, routerReducer} = createReduxHistoryContext({history: createBrowserHistory()})

// persist the redux data
const persistConfig = {
    key: "root",
    storage: storage,
    blacklist: ['router']
}
const baseReducer = combineReducers({
    ...rootReducer,
    router: routerReducer
})
const persistedReducer = persistReducer(persistConfig, baseReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        },
    }).concat(routerMiddleware)
})

export const persistor = persistStore(store)

export const history = createReduxHistory(store)
