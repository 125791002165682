import fetchReducer from "./fetch-reducer"
import issuerReducer from "./issuer-reducer"
import eventReducer from "./event-reducer"

const reducers = {
    fetch: fetchReducer,
    issuer: issuerReducer,
    event: eventReducer,
}

export default reducers
