import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    applyEventInfo: {}, // 数字藏品申请表单信息
    eventInfo: {},  // 事件详情
}

export const issuerReducer = createSlice({
    name: 'event',
    initialState,
    reducers: {
        updateApplyEventInfo: (state, action) => {
            state.applyEventInfo = action.payload
        },
        updateEventInfo: (state, action) => {
            state.eventInfo = action.payload
        },
    }
})

export const {updateApplyEventInfo, updateEventInfo} = issuerReducer.actions

export default issuerReducer.reducer
